const handleServerConnection = (
  destroySession: Function,
  setConnectionLost: Function,
  event: PromiseRejectionEvent
): void => {
  const error = event.reason;
  if (!error.response) {
    // display connection warning
    setConnectionLost(true);
    event.preventDefault();
  } else if (error.response.status === 401 && [1, 2, 3].includes(error.response.data.code)) { // [1: sessionExpired, 2: sessionNotFound, 3: voterNotFound]
    // logout voter
    destroySession("session_expired");
    event.preventDefault();
  }
}

export { handleServerConnection };